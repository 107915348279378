'use client';

import Link from '@ui/components/Link/Link';
import Icon from '@ui/components/icon/icon';
import { Dictionary } from '@ts/dictionary';
import { Heading } from '../Heading/Heading';
import Paragraph from '../Paragraph/Paragraph';

type Props = {
  dictionary: Dictionary;
};

export default function Page404(props: Props) {
  const {
    backToHomePage,
    pageNotFoundHeading,
    pageNotFoundQuoteText,
    pageNotFoundQuoteAuthor,
  } = props.dictionary;

  return (
    <>
      <Link className="inline-flex items-center lg:hover:underline" href="/">
        <Icon name="chevron" className="-rotate-90" />
        {backToHomePage}
      </Link>
      <section className="max-w-3xl lg:mb-[6.5rem]">
        <header>
          <Heading
            as="h1"
            className="mb-10 mt-8 text-4xl leading-tight lg:mb-[4.5rem] lg:text-[3.5rem]">
            {pageNotFoundHeading}
          </Heading>
        </header>
        <blockquote className="mb-4 text-2xl font-bold leading-[2rem] lg:mb-6 lg:text-4xl lg:leading-[2.75rem]">
          {pageNotFoundQuoteText}
        </blockquote>
        <Paragraph className="mb-6">{pageNotFoundQuoteAuthor}</Paragraph>
      </section>
    </>
  );
}
